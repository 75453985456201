import { userApis } from "apis";

export class C_INS_TYPES_DATASOURCE implements userApis.I_INS_TYPES_DATASOURCE {
  key: string;
  name: string;
  redirLink: string;

  constructor(args: userApis.I_INS_TYPES_DATASOURCE) {
    for (const key in args) {
      if (key === "key") {
        this[key] = args[key];
      } else if (key === "name") {
        this[key] = args[key];
      } else if (key === "redirLink") {
        this[key] = args[key];
      }
    }
  }

  getKey(): string {
    return this.key;
  }

  getName(): string {
    return this.name;
  }

  getRedirLink(): string {
    return this.redirLink;
  }
}
