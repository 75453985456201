export const CommonHelpersAddItemToLS = <T,>(key: string, data: T): boolean => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  }
  return false;
};

export const CommonHelpersRemoveItemFromLS = (key: string): boolean => {
  if (localStorage) {
    localStorage.removeItem(key);
    return true;
  }
  return false;
};

export const CommonHelpersGetItemFromLS = <T,>(key: string): T | null => {
  if (localStorage) {
    const data = localStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    }
  }
  return null;
};

export const CommonHelpersGetAllLSItems = (): Record<string, string | null> | null => {
  return { ...localStorage };
};
