import { APIRequest } from "common/helpers";
import { I_INS_TYPES_USER } from "./definitions";

export const getUserDetails = (): APIRequest<I_INS_TYPES_USER> => {
  return (fetch) =>
    fetch(
      "user/",
      {},
      {
        method: "GET",
      },
    );
};
