import dayjs from "dayjs";

export class C_INS_TYPES_JWT {
  user_id: number;
  email: string;
  username: string;
  exp: number;
  challenge_base_url: string;

  constructor(args: Record<string, any>) {
    for (const key in args) {
      if (key === "user_id" && args[key] != null) {
        this[key] = args[key] as number;
      } else if (key === "email") {
        this[key] = args[key];
      } else if (key === "username") {
        this[key] = args[key];
      } else if (key === "exp" && args[key] != null) {
        this[key] = args[key] as number;
      } else if (key === "challenge_base_url") {
        this[key] = args[key];
      }
    }
  }

  getUserId(): number {
    return this.user_id;
  }

  getUsername(): string {
    return this.username;
  }

  getEmail(): string {
    return this.email;
  }

  getExp(): dayjs.Dayjs {
    return dayjs.unix(this.exp);
  }

  getChallengeBaseUrl(): string {
    return "https://" + this.challenge_base_url + "/";
  }
}
