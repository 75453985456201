import { I_INSTINGO_INS_CONFIG } from "./instingo.types";

export const INSTINGO_INS_CONFIG: I_INSTINGO_INS_CONFIG = {
  SENTRY_DSN: "https://3cc494ea4ffb44438d9338fbcee2491e@sentry.instingo.de/3",
  APIS: {
    DEFAULT: {
      BASE_URL: import.meta.env.PROD ? "https://api.instingo.de/v1/" : `${window.location.origin}/INS_DEFAULT/`,
    },
  },
  APP: {
    CONFIG: {
      LS: {
        THEME_KEY: "INS_THEME_KEY",
        USER_KEY: "INS_CC_USER_KEY",
        SELECTED_LOCALE: "INS_LOCALED_KEY",
        AUTH_CLIENTS_FILTER: "AUTH_CLIENTS_FILTER",
      },
      COOKIES: {
        USER_LOGIN_KEY: "instingo.user",
      },
      URLS: {
        STATIC_SERVER: window.location.origin + "/INS_STATIC/",
      },
      PREFERRED_LOCALE: "en",
      AVAILABLE_LANGUAGES: ["de", "en", "fr", "es", "pl", "fi"],
      PRIVACY_POLICY_URL: "https://www.instingo.de/datenschutz/",
    },
    CLIENTS: [
      {
        TITLE: "Instingo",
        CLIENT_ID: "Instingo",
        THEME: {
          IMAGES: {
            LOGO_URI: "assets/images/default/logo.png",
            ICON_URI: "assets/images/default/icon.ico",
          },
        },
      },
    ],
  },
};
