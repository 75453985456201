import {
  getUserDetails,
  login,
  getUserActivities,
  getUserTeam,
  userApis,
  createUserTeam,
  addUserSteps,
  addUserActivity,
  setUserSettings,
} from "apis";
import {
  APIWrapper,
  CommonHelpersGetChallengeBaseUrl,
  CommonHelpersGetCookie,
  CommonHelpersGetPrefferedLanguage,
  create,
} from "common/helpers";
import { INSTINGO_INS_CONFIG } from "instingo.constants";
import {
  C_INS_TYPES_ACTIVITY,
  C_INS_TYPES_USER,
  C_INS_TYPES_USER_TEAM,
  E_INS_TYPES_ACTIVITY_PERIOD_FILTER,
} from "types";

export class UserService {
  private _userApi: APIWrapper;
  private _loginApi: APIWrapper;

  constructor() {
    const userCookie = CommonHelpersGetCookie(INSTINGO_INS_CONFIG.APP.CONFIG.COOKIES.USER_LOGIN_KEY);

    this._userApi = create({
      baseUrl: CommonHelpersGetChallengeBaseUrl(userCookie),
      staticParameters: {
        lang: CommonHelpersGetPrefferedLanguage(),
      },
      requestInit: {
        headers: userCookie
          ? {
              Authorization: `JWT ${userCookie}`,
            }
          : undefined,
      },
    });

    this._loginApi = create({
      baseUrl: INSTINGO_INS_CONFIG.APIS.DEFAULT.BASE_URL,
    });
  }

  login = (username: string, password: string): Promise<C_INS_TYPES_USER> => {
    return this._loginApi
      .request(
        login({
          login_id: username,
          password: password,
        }),
      )
      .then((rawResponse) => {
        return new C_INS_TYPES_USER(rawResponse as userApis.I_INS_TYPES_USER);
      });
  };

  getUserDetails = (): Promise<C_INS_TYPES_USER> => {
    return this._userApi.request(getUserDetails()).then((rawResponse) => {
      return new C_INS_TYPES_USER(rawResponse);
    });
  };

  getUserActivities = (timeRangeFilter?: E_INS_TYPES_ACTIVITY_PERIOD_FILTER): Promise<C_INS_TYPES_ACTIVITY> => {
    return this._userApi.request(getUserActivities(timeRangeFilter)).then((rawResponse) => {
      return new C_INS_TYPES_ACTIVITY(rawResponse);
    });
  };

  getUserTeam = (): Promise<C_INS_TYPES_USER_TEAM | null> => {
    return this._userApi.request(getUserTeam()).then((rawResponse) => {
      if (Object.keys(rawResponse).length) {
        return new C_INS_TYPES_USER_TEAM(rawResponse);
      }
      return null;
    });
  };

  createUserTeam = (newTeam: userApis.I_INS_TYPES_NEW_TEAM): Promise<C_INS_TYPES_USER_TEAM | null> => {
    return this._userApi.request(createUserTeam(newTeam)).then((rawResponse) => {
      if (Object.keys(rawResponse).length) {
        return new C_INS_TYPES_USER_TEAM(rawResponse);
      }
      return null;
    });
  };

  addUserSteps = (manualSteps: userApis.I_INS_TYPES_NEW_MANUAL_STEPS): Promise<C_INS_TYPES_USER | null> => {
    return this._userApi.request(addUserSteps(manualSteps)).then((rawResponse) => {
      if (Object.keys(rawResponse).length) {
        return new C_INS_TYPES_USER(rawResponse);
      }
      return null;
    });
  };

  addUserActivity = (manualActivity: userApis.I_INS_TYPES_NEW_MANUAL_ACTIVITY): Promise<C_INS_TYPES_USER | null> => {
    return this._userApi.request(addUserActivity(manualActivity)).then((rawResponse) => {
      if (Object.keys(rawResponse).length) {
        return new C_INS_TYPES_USER(rawResponse);
      }
      return null;
    });
  };

  setUserSettings = (settings: userApis.I_INS_TYPES_USER_SETTINGS): Promise<C_INS_TYPES_USER> => {
    return this._userApi.request(setUserSettings(settings)).then((rawResponse) => {
      return new C_INS_TYPES_USER(rawResponse);
    });
  };
}
