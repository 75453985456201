import { FC, lazy, Suspense } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { CommonProgress } from "common/components";
import { CommonGlobalProvider } from "common/contexts";
import { CommonHelpersComponentRenderLogger } from "common/helpers";

const App = lazy(() => import("views/app/App")),
  Pages = lazy(() => import("views/pages/Pages"));

export const InstingoWebviewsApp: FC = () => {
  CommonHelpersComponentRenderLogger(InstingoWebviewsApp);

  return (
    <BrowserRouter>
      <CommonGlobalProvider>
        <Suspense fallback={<CommonProgress loading={true} />}>
          <Routes>
            <Route path={"/"}>
              <Route path={"/"} element={<Navigate replace to={"/app"} />} />
            </Route>
            <Route path={"app/*"} element={<App />} />
            <Route path={"pages/*"} element={<Pages />} />
            <Route path={"*"} element={<Pages />} />
          </Routes>
        </Suspense>
      </CommonGlobalProvider>
    </BrowserRouter>
  );
};
