import { APIRequest } from "common/helpers";
import { I_INS_TYPES_NEW_TEAM, I_INS_TYPES_USER_TEAM } from "../definitions";

export const createUserTeam = (newTeam: I_INS_TYPES_NEW_TEAM): APIRequest<I_INS_TYPES_USER_TEAM> => {
  return (fetch) =>
    fetch(
      "user/team/create/",
      { ...newTeam },
      {
        method: "POST",
      },
    );
};
