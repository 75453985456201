import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/pl";
import "dayjs/locale/fi";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { createContext, ReactNode, useState, useEffect, FC } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Locale } from "antd/es/locale";
import deDE from "antd/lib/locale/de_DE";
import enUS from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";
import esES from "antd/lib/locale/es_ES";
import fiFI from "antd/lib/locale/fi_FI";
import plPL from "antd/lib/locale/pl_PL";
import {
  CommonHelpersAddItemToLS,
  CommonHelpersGetAllCookies,
  CommonHelpersGetAllLSItems,
  CommonHelpersGetAppVersion,
  CommonHelpersGetItemFromLS,
  CommonHelpersGetPrefferedLanguage,
  CommonHelpersGetUserCookie,
  CommonHelpersSetUserCookie,
} from "common/helpers";
import { CommonProgress } from "../../components";
import { C_INS_TYPES_USER } from "types";
import { I_INSTINGO_INS_CONFIG_APP_CLIENT_OBJECT } from "instingo.types";
import { INSTINGO_INS_CONFIG } from "instingo.constants";
import {
  T_INS_TYPES_COMMON_THEME,
  E_INS_TYPES_COMMON_THEME_CLIENT_USAGE,
  E_INS_TYPES_COMMON_THEME_APP,
} from "../../types";
import { i18n_de, i18n_en, i18n_fr, i18n_es, i18n_fi, i18n_pl } from "translations";
import { UserService } from "services";

const DEFAULT_LOCALE = enUS;

type T_INS_THEME_OBJ = T_INS_TYPES_COMMON_THEME & {
  theme: I_INSTINGO_INS_CONFIG_APP_CLIENT_OBJECT;
};

type T_USER_SETTINGS = {
  push_notification?: boolean;
  email_notification?: boolean;
};

type CommonGlobalContextProps = {
  isLoading: () => boolean;
  isAuthenticated: () => C_INS_TYPES_USER | null;
  getLanguage: () => string | undefined;
  getClientUsage: () => E_INS_TYPES_COMMON_THEME_CLIENT_USAGE | null;
  getClientName: () => string | null;
  changeLanguage: (lang: string) => Promise<void>;
  updateUserSettings: (settings: T_USER_SETTINGS) => void;
};

const CommonGlobalContext = createContext<CommonGlobalContextProps>({
  isLoading: () => false,
  isAuthenticated: () => null,
  getLanguage: () => DEFAULT_LOCALE.locale,
  getClientUsage: (): E_INS_TYPES_COMMON_THEME_CLIENT_USAGE | null => null,
  getClientName: (): string | null => null,
  changeLanguage: () => Promise.resolve(),
  updateUserSettings: () => {},
});

type Props = {
  children: ReactNode;
};

type GlobalState = {
  loading: boolean;
  authenticated: C_INS_TYPES_USER | null;
  clientUsage?: E_INS_TYPES_COMMON_THEME_CLIENT_USAGE | null;
  client?: string | null;
};

const CommonGlobalProvider: FC<Props> = (props) => {
  const navigate = useNavigate();
  const search = useSearchParams();
  const location = useLocation();
  const [globalState, setGlobalState] = useState<GlobalState>({
    loading: true,
    authenticated: null,
    clientUsage: null,
    client: null,
  });
  const [antdLocalConfig, setAntdLocaleConfig] = useState<Locale | undefined>();

  i18next.on("languageChanged", (newLang: string) => {
    setAntdLocaleConfig(_getLanguageFromLocale(newLang));
    dayjs.extend(updateLocale);
    dayjs.updateLocale(newLang, {
      weekStart: 1,
    });
    document.documentElement.lang = newLang;
  });

  useEffect(() => {
    const userService = new UserService();
    const themeObj = _getThemeObj();

    _updateLanguage(getLanguage() || CommonHelpersGetPrefferedLanguage());

    Sentry.setContext("cookies", CommonHelpersGetAllCookies());
    Sentry.setExtras({
      cookies: CommonHelpersGetAllCookies(),
    });
    Sentry.setExtras({
      localStorage: CommonHelpersGetAllLSItems(),
    });
    Sentry.setTag("version", CommonHelpersGetAppVersion());
    Sentry.setTag("locale", getLanguage() || CommonHelpersGetPrefferedLanguage());

    (themeObj.userJWT ? userService.getUserDetails() : Promise.reject("User Cookie Not Found!"))
      .then((user) => {
        Sentry.setUser(JSON.parse(JSON.stringify(user)));

        setGlobalState({
          ...globalState,
          authenticated: user,
          loading: false,
          client: themeObj.client,
          clientUsage: themeObj.clientUsage,
        });
      })
      .catch(async (err) => {
        let errorItem =
          err instanceof Response
            ? {
                url: err.url,
                type: err.type,
                status: err.status,
                err: JSON.stringify(await err.json()),
              }
            : err;

        if (Object.keys(errorItem).length === 0) {
          errorItem = err;
        }

        errorItem = JSON.stringify(errorItem);

        if (errorItem === "{}") {
          errorItem = err;
          Sentry.captureException(errorItem);
        } else {
          Sentry.captureMessage("[CommonGlobalContext]: " + errorItem);
        }

        Sentry.setUser(null);

        if (themeObj.clientUsage === E_INS_TYPES_COMMON_THEME_CLIENT_USAGE.APP) {
          _redirectToNoAccess();
        } else {
          _redirectToLogin();
        }

        setGlobalState({
          ...globalState,
          authenticated: null,
          loading: false,
          client: themeObj.client,
          clientUsage: themeObj.clientUsage,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _initializeI18Next(getLanguage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** PRIVATE FUNCTIONS **/

  const _updateLanguage = (newLang: string) => {
    Sentry.setTag("locale", newLang);

    if (dayjs.locale() !== newLang) {
      dayjs.locale(newLang);
    }
    if (antdLocalConfig?.locale !== newLang) {
      setAntdLocaleConfig(_getLanguageFromLocale(newLang));
    }
  };

  const _getLanguageFromLocale = (newLangLocale: string) => {
    return newLangLocale === deDE.locale
      ? deDE
      : newLangLocale === frFR.locale
      ? frFR
      : newLangLocale === esES.locale
      ? esES
      : newLangLocale === fiFI.locale
      ? fiFI
      : newLangLocale === plPL.locale
      ? plPL
      : newLangLocale === enUS.locale
      ? enUS
      : DEFAULT_LOCALE;
  };

  const _initializeI18Next = (prefferedlang?: string) => {
    return i18next.init({
      compatibilityJSON: "v3",
      returnNull: false,
      lng: prefferedlang,
      debug: !import.meta.env.PROD,
      fallbackLng: INSTINGO_INS_CONFIG.APP.CONFIG.PREFERRED_LOCALE,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: {
          translation: i18n_en,
        },
        de: {
          translation: i18n_de,
        },
        fr: {
          translation: i18n_fr,
        },
        es: {
          translation: i18n_es,
        },
        pl: {
          translation: i18n_pl,
        },
        fi: {
          translation: i18n_fi,
        },
      },
    });
  };

  const _getThemeObjFromURL = (): T_INS_TYPES_COMMON_THEME | null => {
    const [searchParams] = search,
      client = searchParams.get("client"),
      clientUsage = searchParams.get("clientUsage"),
      userJWT = searchParams.get("userJWT"),
      themeObj: T_INS_TYPES_COMMON_THEME = {};

    if (userJWT && !Array.isArray(userJWT)) {
      themeObj.userJWT = userJWT;
    }

    if (client && !Array.isArray(client)) {
      themeObj.client = client.toUpperCase();
    }

    if (clientUsage && !Array.isArray(clientUsage)) {
      themeObj.clientUsage =
        clientUsage.toUpperCase() == "APP"
          ? E_INS_TYPES_COMMON_THEME_CLIENT_USAGE.APP
          : E_INS_TYPES_COMMON_THEME_CLIENT_USAGE.WEB;
    }

    return Object.keys(themeObj).length > 0 ? themeObj : null;
  };

  const _getThemeObjFromLS = (): T_INS_TYPES_COMMON_THEME | null => {
    const data = CommonHelpersGetItemFromLS(INSTINGO_INS_CONFIG.APP.CONFIG.LS.THEME_KEY);

    if (data) {
      return data as T_INS_TYPES_COMMON_THEME;
    }
    return null;
  };

  const _getThemeObj = (): T_INS_THEME_OBJ => {
    let themeObj: T_INS_TYPES_COMMON_THEME | null = _getThemeObjFromURL();

    if (themeObj) {
      CommonHelpersAddItemToLS(INSTINGO_INS_CONFIG.APP.CONFIG.LS.THEME_KEY, themeObj);

      if (themeObj.userJWT) {
        CommonHelpersSetUserCookie(themeObj.userJWT);
      } else if (CommonHelpersGetUserCookie()) {
        themeObj.userJWT = CommonHelpersGetUserCookie();
      }
    } else {
      themeObj = _getThemeObjFromLS();
    }

    const theme = getTheme(themeObj?.client);

    return {
      ...themeObj,
      theme: theme,
      client: themeObj?.client ?? theme.CLIENT_ID,
      userJWT: themeObj?.userJWT ?? CommonHelpersGetUserCookie(),
    };
  };

  const _redirectToNoAccess = () => {
    navigate("pages/no-access");
  };

  const _redirectToLogin = () => {
    navigate("app/login");
  };

  const _getAppName = (): E_INS_TYPES_COMMON_THEME_APP => {
    const { pathname } = location;

    if (pathname && pathname.startsWith("/app")) {
      return E_INS_TYPES_COMMON_THEME_APP.APP;
    } else if (pathname && pathname.startsWith("/pages")) {
      return E_INS_TYPES_COMMON_THEME_APP.PAGES;
    }

    return E_INS_TYPES_COMMON_THEME_APP.APP;
  };

  /** PUBLIC FUNCTIONS **/

  const isLoading = () => {
    return globalState.loading;
  };

  const isAuthenticated = () => {
    return globalState.authenticated;
  };

  const updateUserSettings = (settings: T_USER_SETTINGS) => {
    if (globalState.authenticated != null) {
      const user = globalState.authenticated;

      if (settings.push_notification != null) {
        user.push_notification = settings.push_notification;
      } else if (settings.email_notification != null) {
        user.email_notification = settings.email_notification;
      }

      setGlobalState({
        ...globalState,
        authenticated: user,
      });
    }
  };

  const getTheme = (client?: string): I_INSTINGO_INS_CONFIG_APP_CLIENT_OBJECT => {
    let theme = INSTINGO_INS_CONFIG.APP.CLIENTS.filter((client) => client.CLIENT_ID.toUpperCase() === "INSTINGO")[0];
    const appName = _getAppName();

    if (client) {
      INSTINGO_INS_CONFIG.APP.CLIENTS.forEach((clientObj) => {
        if (appName === E_INS_TYPES_COMMON_THEME_APP.APP) {
          if (clientObj.CLIENT_ID.toUpperCase() === client.toUpperCase()) {
            theme = clientObj;
          }
        } else if (appName === E_INS_TYPES_COMMON_THEME_APP.PAGES) {
          if (clientObj.CLIENT_ID.toUpperCase() === client.toUpperCase()) {
            theme = clientObj;
          }
        }
      });
    }

    return theme;
  };

  const getLanguage = () => {
    const [searchParams] = search,
      lang = searchParams.get("lang");
    if (!Array.isArray(lang)) {
      return CommonHelpersGetPrefferedLanguage(lang);
    } else {
      return CommonHelpersGetPrefferedLanguage(lang[0]);
    }
  };

  const getClientUsage = (): E_INS_TYPES_COMMON_THEME_CLIENT_USAGE | null => {
    if (globalState.clientUsage) {
      return globalState.clientUsage;
    }
    return null;
  };

  const getClientName = (): string | null => {
    if (globalState.client) {
      const client = globalState.client;

      return client;
    }
    return null;
  };

  const changeLanguage = (lang: string): Promise<void> => {
    CommonHelpersAddItemToLS(INSTINGO_INS_CONFIG.APP.CONFIG.LS.SELECTED_LOCALE, lang);
    return i18next.changeLanguage(lang).then(() => {
      document.documentElement.lang = CommonHelpersGetPrefferedLanguage();
    });
  };

  return (
    <CommonGlobalContext.Provider
      value={{
        changeLanguage,
        isLoading,
        isAuthenticated,
        getClientUsage,
        getLanguage,
        getClientName,
        updateUserSettings,
      }}
    >
      <CommonProgress loading={globalState.loading}>
        <I18nextProvider i18n={i18next}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#5FC7E5",
                colorTextHeading: "#4a4a4a",
                colorLink: "#5FC7E5",
                colorSuccess: "#9BC943",
                colorError: "#F30707",
                borderRadius: 8,
                colorBorder: "#4a4a4a",
                fontFamily: "'Open Sans', sans-serif",
                fontSize: 15,
                fontSizeHeading5: 16,
              },
              components: {
                Card: {
                  colorBgContainer: "#cdecef",
                },
                Button: {
                  colorPrimary: "#4a4a4a",
                  colorBgContainer: "#4a4a4a",
                  colorPrimaryHover: "#575757",
                  colorLinkHover: "#4a4a4a",
                },
                Radio: {
                  colorBgContainer: "#b9b9b9",
                },
              },
            }}
            locale={antdLocalConfig}
          >
            {props.children}
          </ConfigProvider>
        </I18nextProvider>
      </CommonProgress>
    </CommonGlobalContext.Provider>
  );
};

const CommonGlobalConsumer = CommonGlobalContext.Consumer;

export { CommonGlobalContext, CommonGlobalProvider, CommonGlobalConsumer };
