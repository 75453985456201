import { userApis } from "apis";

export class C_INS_TYPES_POINTS implements userApis.I_INS_TYPES_POINTS {
  total: number;
  avg?: number;
  from: Record<string, number>;

  constructor(args: userApis.I_INS_TYPES_POINTS) {
    for (const key in args) {
      if (key === "total") {
        this[key] = args[key];
      } else if (key === "from") {
        this[key] = args[key];
      } else if (key === "avg") {
        this[key] = args[key];
      }
    }
  }

  getTotal(): number {
    return this.total;
  }

  getFrom(): Record<string, number> {
    return this.from;
  }

  getAverage(): number | null {
    return this.avg ?? null;
  }

  getPointsList(): C_INS_TYPES_POINTS_ITEM[] {
    return Object.entries(this.from).map(([name, total]) => {
      return new C_INS_TYPES_POINTS_ITEM({ name, total });
    });
  }

  getPointsFrom(from: string): number | null {
    if (Object.keys(this.from).includes(from)) {
      return this.from[from];
    }

    return null;
  }
}

export class C_INS_TYPES_POINTS_ITEM implements userApis.I_INS_TYPES_POINTS_ITEM {
  name: string;
  total: number;

  constructor(args: userApis.I_INS_TYPES_POINTS_ITEM) {
    for (const key in args) {
      if (key === "total") {
        this[key] = args[key];
      } else if (key === "name") {
        this[key] = args[key];
      }
    }
  }

  getTotal(): number {
    return this.total;
  }

  getName(): string {
    return this.name;
  }
}
