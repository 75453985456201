import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Image, Row, Col } from "antd";
import { CommonHelpersComponentRenderLogger } from "common/helpers";

import "./CommonProgress.css";

import InstingoIcon from "assets/images/default/icon.png";

type Props = {
  children?: ReactNode;
  loading: boolean;
  timeoutMs?: number;
};

const CommonProgress: FC<Props> = ({ children, loading, timeoutMs = 500 }) => {
  CommonHelpersComponentRenderLogger(CommonProgress);
  const [complete, setComplete] = useState<boolean>(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!loading) {
      if (timeoutMs !== 0) {
        timeout.current = setTimeout(() => {
          setComplete(true);
        }, 250);
      } else {
        setComplete(true);
      }
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return complete ? (
    <>{children}</>
  ) : (
    <Row className="full-height-colored">
      <Col span={24} className="vertical-align-center custom-background">
        <Image preview={false} src={InstingoIcon} className="common-progress" alt="Loading..." />
      </Col>
    </Row>
  );
};

export { CommonProgress };
