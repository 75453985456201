import { userApis } from "apis";
import { C_INS_TYPES_USER_TEAM_MEMBER } from "./UserTeamMember";
import { C_INS_TYPES_POINTS } from "./Points";

export type T_INS_TYPES_USER_TEAM_ID = number;

export class C_INS_TYPES_USER_TEAM implements userApis.I_INS_TYPES_USER_TEAM {
  id: T_INS_TYPES_USER_TEAM_ID;
  average: number | null;
  members: number | null;
  name: string | null;
  rank: number;
  total_steps: number | null;
  user_can_leave: boolean;
  team_members_list: C_INS_TYPES_USER_TEAM_MEMBER[];
  points?: C_INS_TYPES_POINTS;

  constructor(args: userApis.I_INS_TYPES_USER_TEAM) {
    for (const key in args) {
      if (key === "team_members_list") {
        this[key] = [];
        args[key]?.forEach((teamMember) => {
          this[key].push(new C_INS_TYPES_USER_TEAM_MEMBER(teamMember));
        });
      } else if (key === "id") {
        this[key] = args[key];
      } else if (key === "average") {
        this[key] = args[key];
      } else if (key === "members") {
        this[key] = args[key];
      } else if (key === "name") {
        this[key] = args[key];
      } else if (key === "rank") {
        this[key] = args[key];
      } else if (key === "total_steps") {
        this[key] = args[key];
      } else if (key === "user_can_leave") {
        this[key] = args[key];
      } else if (key === "points" && args[key] != null) {
        this[key] = new C_INS_TYPES_POINTS(args[key]!);
      }
    }
  }

  getId = (): T_INS_TYPES_USER_TEAM_ID => {
    return this.id;
  };

  getAverage = (): number | null => {
    return this.average;
  };

  getMembers = (): number | null => {
    return this.members;
  };

  getName = (): string | null => {
    return this.name;
  };

  getRank = (): number => {
    return this.rank;
  };

  getTotalSteps = (): number | null => {
    return this.total_steps;
  };

  userCanLeave = (): boolean => {
    return this.user_can_leave;
  };

  getTeamMembersList = (): C_INS_TYPES_USER_TEAM_MEMBER[] => {
    return this.team_members_list;
  };

  getPoints = (): C_INS_TYPES_POINTS | null => {
    return this.points ?? null;
  };
}
