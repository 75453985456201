import { APIRequest } from "common/helpers";
import { I_INS_TYPES_USER, I_INS_TYPES_NEW_MANUAL_ACTIVITY } from "./definitions";

export const addUserActivity = (manualActivity: I_INS_TYPES_NEW_MANUAL_ACTIVITY): APIRequest<I_INS_TYPES_USER> => {
  return (fetch) =>
    fetch(
      "user/add_activity/",
      {
        ...manualActivity,
      },
      {
        method: "POST",
      },
    );
};
