import { userApis } from "apis";
import { C_INS_TYPES_DATASOURCE } from "./Datasource";
import { C_INS_TYPES_ACTIVITY_RECORD } from "./ActivityRecord";
import { C_INS_TYPES_POINTS } from "./Points";

export enum E_INS_TYPES_ACTIVITY_PERIOD_FILTER {
  DAILY = "day",
  WEEKLY = "week",
  MONTHLY = "month",
  TOTAL = "total",
}

export type T_INS_TYPES_ACTIVITY_ID = number;

export class C_INS_TYPES_ACTIVITY implements userApis.I_INS_TYPES_ACTIVITY {
  id: T_INS_TYPES_ACTIVITY_ID;
  username: string;
  manual_steps: number;
  tracked_steps: number;
  datasources: C_INS_TYPES_DATASOURCE[];
  records: C_INS_TYPES_ACTIVITY_RECORD[];
  points?: C_INS_TYPES_POINTS;

  constructor(args: userApis.I_INS_TYPES_ACTIVITY) {
    for (const key in args) {
      if (key === "datasources") {
        this[key] = [];
        args[key]?.forEach((datasource) => {
          this[key]?.push(new C_INS_TYPES_DATASOURCE(datasource));
        });
      } else if (key === "records") {
        this[key] = [];
        args[key]?.forEach((record) => {
          this[key]?.push(new C_INS_TYPES_ACTIVITY_RECORD(record));
        });
      } else if (key === "id") {
        this[key] = args[key];
      } else if (key === "username") {
        this[key] = args[key];
      } else if (key === "manual_steps" && args[key] != null) {
        this[key] = args[key] as number;
      } else if (key === "tracked_steps" && args[key] != null) {
        this[key] = args[key] as number;
      } else if (key === "points" && args[key] != null) {
        this[key] = new C_INS_TYPES_POINTS(args[key]!);
      }
    }
  }

  getId(): T_INS_TYPES_ACTIVITY_ID {
    return this.id;
  }

  getUsername(): string {
    return this.username;
  }

  getManualSteps(): number {
    return this.manual_steps;
  }

  getTrackedSteps(): number {
    return this.tracked_steps;
  }

  getDatasources(): C_INS_TYPES_DATASOURCE[] {
    return this.datasources;
  }

  getRecords(): C_INS_TYPES_ACTIVITY_RECORD[] {
    return this.records;
  }

  getTotalSteps(): number {
    return this.manual_steps + this.tracked_steps;
  }

  getPoints(): C_INS_TYPES_POINTS | null {
    return this.points ?? null;
  }
}
