import queryString from "query-string";

const RAW_RESPONSE = Symbol("raw");

export interface RawResponseContainer {
  [RAW_RESPONSE]: Response;
}
const defaultsDeep = (...args: Record<string, any>[]): any => {
  let result: Record<string, any> = {};

  args.forEach((arg) => {
    const newObj: Record<string, any> = {};

    Object.keys(arg).forEach((argKey) => {
      if (typeof arg[argKey] === "object") {
        if (result[argKey]) {
          newObj[argKey] = { ...result[argKey], ...defaultsDeep(arg[argKey]) };
        } else {
          newObj[argKey] = defaultsDeep(arg[argKey]);
        }
      } else {
        newObj[argKey] = arg[argKey];
      }
    });

    result = { ...result, ...defaults(newObj) };
  });

  return result;
};

const defaults = (...args: Record<string, any>[]) => {
  return args.reverse().reduce((acc, obj) => ({ ...acc, ...obj }), {});
};

const attachRawResponseData = <T extends Record<string, unknown>>(
  result: T,
  response: Response,
): T & RawResponseContainer => {
  return {
    ...result,
    [RAW_RESPONSE]: response,
  };
};

export type FetchWrapper<T> = (
  urlPath: string,
  parameters?:
    | string
    | number
    | number[]
    | {
        [index: string]: unknown;
      }
    | null,
  init?: RequestInit,
  options?: {
    bodySerializer?: (
      parameters:
        | string
        | number
        | {
            [index: string]: any;
          }
        | null,
    ) => BodyInit;
    responseDeserializer?: (response: Response) => Promise<T>;
  },
) => Promise<ResultWrapper<T> & RawResponseContainer>;

export type APIRequest<T> = (
  fetch: FetchWrapper<T>,
  config: APIConfiguration,
) => Promise<ResultWrapper<T> & RawResponseContainer>;

export type ResultWrapper<T> = T extends Record<string, any> ? T : { value: T };

type StaticParameters = Record<string, unknown>;
export interface APIConfiguration {
  baseUrl: string;
  fetch?: typeof globalThis.fetch;
  staticParameters?: StaticParameters;
  requestInit?: Partial<RequestInit>;
}

const DEFAULT_STATIC_PARAMETERS: StaticParameters = {};

export type APIRequestResponse<T> = ResultWrapper<T> & RawResponseContainer;

export interface APIWrapper {
  request<T>(apiRequest: APIRequest<T>): Promise<APIRequestResponse<T>>;
}

export function create(configuration: APIConfiguration): APIWrapper {
  return {
    async request<T>(apiRequest: APIRequest<T>) {
      const { staticParameters = DEFAULT_STATIC_PARAMETERS, requestInit, fetch: activeFetch = fetch } = configuration;

      const fetchWrapper: FetchWrapper<T> = async (urlPath, parameters = {}, init = {}, options = {}) => {
        const {
          bodySerializer = JSON.stringify,
          responseDeserializer = async (response: Response): Promise<Record<string, any>> => {
            try {
              return JSON.parse((await response.text()) || "{}");
            } catch (e) {
              return {};
            }
          },
        } = options;

        const isPrimitiveParameter = typeof parameters !== "object";
        const params = !isPrimitiveParameter ? defaultsDeep({}, staticParameters, parameters ?? {}) : parameters;
        const isGET = !init.method || init.method === "GET";
        const url = new URL(
          `${urlPath}${isGET && Object.keys(params).length > 0 ? `?${queryString.stringify(params)}` : ""}`,
          configuration.baseUrl,
        );
        const body = !isGET
          ? {
              body: bodySerializer(parameters),
            }
          : {};

        const response: Response | undefined = await activeFetch(
          url.toString(),
          defaultsDeep({}, body, init, requestInit ?? {}, {
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
          }),
        );

        if (!response.ok) {
          throw response;
        }

        const result: any = await responseDeserializer(response.clone());

        return attachRawResponseData(
          result instanceof Array
            ? { value: result }
            : typeof result === "object" && result !== null
            ? result
            : { value: result },
          response,
        );
      };

      return await apiRequest(fetchWrapper, configuration);
    },
  };
}
