import { userApis } from "apis";

export class C_INS_TYPES_USER_TEAM_MEMBER implements userApis.I_INS_TYPES_USER_TEAM_MEMBERS_LIST_ITEM {
  name: string;
  steps: number;

  constructor(args: userApis.I_INS_TYPES_USER_TEAM_MEMBERS_LIST_ITEM) {
    this.name = Object.keys(args)[0];
    this.steps = Object.values(args)[0];
  }

  getName = (): string => {
    return this.name;
  };

  getSteps = (): number => {
    return this.steps;
  };
}
