import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

import { CommonHelpersGetAppVersion, CommonHelpersGetAllCookies } from "common/helpers";
import { INSTINGO_INS_CONFIG } from "instingo.constants";
import { InstingoWebviewsApp } from "./App";

import "./index.css";

Sentry.init({
  debug: !import.meta.env.PROD,
  attachStacktrace: true,
  dsn: INSTINGO_INS_CONFIG.SENTRY_DSN,
  release: CommonHelpersGetAppVersion(),
  environment: import.meta.env.MODE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
  initialScope: (scope) => {
    scope.setContext("userInfo", {
      cookies: CommonHelpersGetAllCookies(),
      version: CommonHelpersGetAppVersion(),
    });
    return scope;
  },
  sampleRate: 1.0,
});

createRoot(document.getElementById("root") as HTMLElement).render(<InstingoWebviewsApp />);
