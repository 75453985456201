export type T_INS_TYPES_COMMON_THEME = {
  client?: string;
  clientUsage?: E_INS_TYPES_COMMON_THEME_CLIENT_USAGE | null;
  userJWT?: string | null;
};

export enum E_INS_TYPES_COMMON_THEME_CLIENT_USAGE {
  APP = "APP",
  WEB = "WEB",
}

export enum E_INS_TYPES_COMMON_THEME_APP {
  APP = "APP",
  PAGES = "PAGES",
}
