import { userApis } from "apis";
export class C_INS_TYPES_WEBVIEW implements userApis.I_INS_TYPES_WEBVIEW {
  title: string;
  link: string;

  constructor(args: userApis.I_INS_TYPES_WEBVIEW) {
    for (const key in args) {
      if (key === "title") {
        this[key] = args[key];
      } else if (key === "link") {
        this[key] = args[key];
      }
    }
  }

  getTitle(): string {
    return this.title;
  }

  getLink(): string {
    return this.link;
  }
}
