const _b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, function (_, p) {
      let code = p.charCodeAt(0).toString(16).toUpperCase();

      if (code.length < 2) {
        code = "0" + code;
      }

      return "%" + code;
    }),
  );
};

const _base64_url_decode = (str: string) => {
  let output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw Error("Illegal base64url string!");
  }

  try {
    return _b64DecodeUnicode(output);
  } catch (err) {
    return Buffer.from(output, "base64").toString("binary");
  }
};

export const jwtDecode = (token: string, options: Record<string, unknown> = {}): Record<string, unknown> => {
  try {
    return JSON.parse(_base64_url_decode(token.split(".")[options.header === true ? 0 : 1]));
  } catch (err) {
    throw new Error("Invalid token specified: " + err);
  }
};
