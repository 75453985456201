import { APIRequest } from "common/helpers";
import { I_INS_TYPES_USER_TEAM } from "../definitions";

export const getUserTeam = (): APIRequest<I_INS_TYPES_USER_TEAM> => {
  return (fetch) =>
    fetch(
      "user/team/",
      {},
      {
        method: "GET",
      },
    );
};
