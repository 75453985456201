import { APIRequest } from "common/helpers";
import { I_INS_TYPES_USER, I_INS_TYPES_NEW_MANUAL_STEPS } from "./definitions";

export const addUserSteps = (manualSteps: I_INS_TYPES_NEW_MANUAL_STEPS): APIRequest<I_INS_TYPES_USER> => {
  return (fetch) =>
    fetch(
      "user/add_steps/",
      {
        ...manualSteps,
      },
      {
        method: "POST",
      },
    );
};
