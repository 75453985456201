import { userApis } from "apis";

export class C_INS_TYPES_MANUAL_ACTIVITY implements userApis.I_INS_TYPES_MANUAL_ACTIVITY {
  name: string;
  in_steps: number;
  hide_minutes: boolean;

  constructor(args: userApis.I_INS_TYPES_MANUAL_ACTIVITY) {
    for (const key in args) {
      if (key === "in_steps" && args[key] != null) {
        this[key] = args[key] as number;
      } else if (key === "name") {
        this[key] = args[key];
      } else if (key === "hide_minutes") {
        this[key] = args[key];
      }
    }
  }

  getActivityName(): string {
    return this.name;
  }

  getActivitySteps(): number {
    return this.in_steps;
  }

  hasMinutes(): boolean {
    return !this.hide_minutes;
  }
}
