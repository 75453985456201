import { APIRequest } from "common/helpers";
import { I_INS_TYPES_USER, I_INS_TYPES_USER_SETTINGS } from "./definitions";

export const setUserSettings = (settings: I_INS_TYPES_USER_SETTINGS): APIRequest<I_INS_TYPES_USER> => {
  return (fetch) =>
    fetch(
      "user/settings/",
      { ...settings },
      {
        method: "POST",
      },
    );
};
