import dayjs from "dayjs";
import { FC, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { INSTINGO_INS_CONFIG } from "instingo.constants";
import { C_INS_TYPES_JWT } from "types";
import { jwtDecode } from "./CommonJWTHelpers";
import { CommonHelpersAddItemToLS, CommonHelpersGetItemFromLS } from "./CommonLSHelpers";
import metadata from "../../../metadata.json";
import { notification } from "antd";
import Icon from "@ant-design/icons";
import InstingoIcon from "assets/images/default/icon.png";

export const CommonHelpersGetAppVersion = (): string => {
  return `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} - ${dayjs(metadata.buildDate).format(
    "DD.MM.YYYY HH:mm",
  )}`;
};

export const CommonHelpersClipboardCopy = (copy: string): void => {
  try {
    navigator?.clipboard?.writeText(copy);
  } catch {
    return;
  }
};

export const CommonHelpersGetPrefferedLanguage = (langFromUrl?: string | null): string => {
  let preferedLang = INSTINGO_INS_CONFIG.APP.CONFIG.PREFERRED_LOCALE;
  const availableLanguages = INSTINGO_INS_CONFIG.APP.CONFIG.AVAILABLE_LANGUAGES,
    localStoragepreferedLang: string | null = CommonHelpersGetItemFromLS(
      INSTINGO_INS_CONFIG.APP.CONFIG.LS.SELECTED_LOCALE,
    );

  if (langFromUrl) {
    if (availableLanguages.indexOf(langFromUrl) > -1) {
      CommonHelpersAddItemToLS(INSTINGO_INS_CONFIG.APP.CONFIG.LS.SELECTED_LOCALE, langFromUrl);
      preferedLang = langFromUrl;
    } else {
      preferedLang = INSTINGO_INS_CONFIG.APP.CONFIG.PREFERRED_LOCALE;
      CommonHelpersAddItemToLS(
        INSTINGO_INS_CONFIG.APP.CONFIG.LS.SELECTED_LOCALE,
        INSTINGO_INS_CONFIG.APP.CONFIG.PREFERRED_LOCALE,
      );
    }
  } else if (localStoragepreferedLang) {
    preferedLang = localStoragepreferedLang;
  } else {
    CommonHelpersAddItemToLS(INSTINGO_INS_CONFIG.APP.CONFIG.LS.SELECTED_LOCALE, preferedLang);
  }

  dayjs.locale(preferedLang);
  return preferedLang;
};

export const CommonHelpersComponentRenderLogger = <T,>(component: FC<T>): void => {
  Sentry.configureScope((scope) => scope.setTransactionName(component.displayName ?? component.name));

  const renderStyle = `
    color: white;
    background-color: blue;
    font-size: 12px;
    font-weight: bold;`;

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.info("%cRENDER", `${renderStyle}`, `${component.displayName ?? component.name}`);
  }
};

export const CommonHelpersUseTitle = (clientName: string | null, title?: string): void => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = [clientName, title].filter(Boolean).join(clientName && title ? " - " : "");
    return () => {
      document.title = prevTitle;
    };
  }, [title, clientName]);
};

export const CommonHelpersPercentage = (value: number, total: number, minVal?: number): number => {
  if (total) {
    const perct = (value * 100) / total;
    const roundedPerct = Math.round(perct);

    if (minVal != null && perct != 0) {
      return roundedPerct >= 1 ? roundedPerct : minVal;
    }

    return roundedPerct;
  }

  return minVal ?? 0;
};

export const CommonHelpersHasPreviousRoute = (): boolean => {
  if (window.history.length > 1) {
    return true;
  }
  return false;
};

export const CommonHelpersGetChallengeBaseUrl = (userCookie: string | null): string => {
  return userCookie && import.meta.env.PROD
    ? new C_INS_TYPES_JWT(jwtDecode(userCookie)).getChallengeBaseUrl()
    : INSTINGO_INS_CONFIG.APIS.DEFAULT.BASE_URL;
};

export const CommonHelpersCapitalize = (str: string | undefined): string | undefined => {
  return str?.replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
};

export const CommonHelpersShowAppVersion = (): void => {
  const appVersion = `V${CommonHelpersGetAppVersion()}`;

  CommonHelpersClipboardCopy(appVersion);

  notification.open({
    key: appVersion,
    duration: 5,
    message: appVersion,
    icon: <Icon component={() => <img style={{ height: 24 }} src={InstingoIcon} alt="instingo" />} />,
  });
};

export const CommonHelpersExternalUrl = (urlString: string): string => {
  const url = new URL(urlString);
  url.searchParams.set("isExternalUrl", "true");
  url.searchParams.set("lang", CommonHelpersGetPrefferedLanguage());

  return url.toString();
};
