import dayjs from "dayjs";
import { userApis } from "apis";

export class C_INS_TYPES_ACTIVITY_RECORD implements userApis.I_INS_TYPES_ACTIVITY_RECORD {
  date: string;
  idate: string;
  steps: number;

  constructor(args: userApis.I_INS_TYPES_ACTIVITY_RECORD) {
    for (const key in args) {
      if (key === "date") {
        this[key] = args[key];
      } else if (key === "idate") {
        this[key] = args[key];
      } else if (key === "steps" && args[key] != null) {
        this[key] = args[key] as number;
      }
    }
  }

  getDate(): dayjs.Dayjs {
    return dayjs(this.date);
  }

  getIDate(): dayjs.Dayjs {
    return dayjs(this.idate);
  }

  getSteps(): number {
    return this.steps;
  }
}
