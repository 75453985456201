import dayjs from "dayjs";
import { userApis } from "apis";
import { C_INS_TYPES_DATASOURCE } from "./Datasource";
import { C_INS_TYPES_WEBVIEW } from "./Webview";
import { C_INS_TYPES_MANUAL_ACTIVITY } from "./ManualActivity";

export type T_INS_TYPES_USER_ID = number;

export class C_INS_TYPES_USER implements userApis.I_INS_TYPES_USER {
  id: T_INS_TYPES_USER_ID;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  month_steps: number;
  month_kcals: number;
  week_steps: number;
  week_kcals: number;
  datasource: string;
  datasources?: C_INS_TYPES_DATASOURCE[];
  synced_at?: string;
  webviews: Record<string, C_INS_TYPES_WEBVIEW>;
  challenge_base_url: string;
  manual_activity_entry_allowed: boolean;
  manual_steps_entry_allowed: boolean;
  team_creating_allowed: boolean;
  average_steps_ranking: boolean;
  token?: string;
  activities_map: C_INS_TYPES_MANUAL_ACTIVITY[];
  possible_ranking_options: string[];
  email_notification: boolean;
  push_notification: boolean;
  is_chat_enabled: boolean;
  hide_rank?: boolean;

  constructor(args: userApis.I_INS_TYPES_USER) {
    for (const key in args) {
      if (key === "datasources") {
        this[key] = [];
        args[key]?.forEach((datasource) => {
          this[key]?.push(new C_INS_TYPES_DATASOURCE(datasource));
        });
      } else if (key === "webviews") {
        this[key] = {};
        Object.keys(args[key])?.forEach((webviewKey) => {
          this[key][webviewKey] = new C_INS_TYPES_WEBVIEW(args[key][webviewKey]);
        });
      } else if (key === "activities_map") {
        this[key] = [];
        args[key]?.forEach((activity) => {
          this[key]?.push(new C_INS_TYPES_MANUAL_ACTIVITY(activity));
        });
      } else if (key === "id") {
        this[key] = args[key];
      } else if (key === "username") {
        this[key] = args[key];
      } else if (key === "email") {
        this[key] = args[key];
      } else if (key === "first_name") {
        this[key] = args[key];
      } else if (key === "last_name") {
        this[key] = args[key];
      } else if (key === "month_steps") {
        this[key] = args[key];
      } else if (key === "month_kcals") {
        this[key] = args[key];
      } else if (key === "week_steps") {
        this[key] = args[key];
      } else if (key === "week_kcals") {
        this[key] = args[key];
      } else if (key === "datasource" && args[key] != null) {
        this[key] = args[key] as string;
      } else if (key === "synced_at") {
        this[key] = args[key];
      } else if (key === "challenge_base_url") {
        this[key] = args[key];
      } else if (key === "manual_activity_entry_allowed") {
        this[key] = args[key];
      } else if (key === "manual_steps_entry_allowed") {
        this[key] = args[key];
      } else if (key === "team_creating_allowed") {
        this[key] = args[key];
      } else if (key === "average_steps_ranking") {
        this[key] = args[key];
      } else if (key === "token") {
        this[key] = args[key];
      } else if (key === "possible_ranking_options") {
        this[key] = args[key];
      } else if (key === "email_notification") {
        this[key] = args[key];
      } else if (key === "push_notification") {
        this[key] = args[key];
      } else if (key === "hide_rank") {
        this[key] = args[key];
      }
    }
  }

  getId(): T_INS_TYPES_USER_ID {
    return this.id;
  }

  getUsername(): string {
    return this.username;
  }

  getEmail(): string {
    return this.email;
  }

  getFirstName(): string {
    return this.first_name;
  }

  getLastName(): string {
    return this.last_name;
  }

  getMonthSteps(): number {
    return this.month_steps;
  }

  getMonthKcals(): number {
    return this.month_kcals;
  }

  getWeekSteps(): number {
    return this.week_steps;
  }

  getWeekKcals(): number {
    return this.week_kcals;
  }

  getDatasource(): string {
    return this.datasource;
  }

  getDatasources(): C_INS_TYPES_DATASOURCE[] | null {
    if (this.datasources) {
      return this.datasources;
    }
    return null;
  }

  getSyncedAt(): dayjs.Dayjs | null {
    if (this.synced_at) {
      return dayjs(this.synced_at);
    }
    return null;
  }

  getWebviews(): Record<string, C_INS_TYPES_WEBVIEW> {
    return this.webviews;
  }

  getChallengeBaseUrl(): string {
    return "https://" + this.challenge_base_url + "/";
  }

  manualActivityEntryIsAllowed(): boolean {
    return this.manual_activity_entry_allowed;
  }

  manualStepsEntryIsAllowed(): boolean {
    return this.manual_steps_entry_allowed;
  }

  teamCreatingIsAllowed(): boolean {
    return this.team_creating_allowed;
  }

  showAverageStepsRanking(): boolean {
    return this.average_steps_ranking;
  }

  getToken(): string | null {
    if (this.token) {
      return this.token;
    }
    return null;
  }

  getActivities(): C_INS_TYPES_MANUAL_ACTIVITY[] {
    return this.activities_map;
  }

  getPossibleRankingOptions(): string[] {
    return this.possible_ranking_options ?? [];
  }

  isEmailNotificationEnabled(): boolean {
    return this.email_notification;
  }

  isPushNotificationEnabled(): boolean {
    return this.push_notification;
  }

  isChatEnabled(): boolean {
    return this.is_chat_enabled;
  }

  hideTeamsRanks = (): boolean => {
    return this.hide_rank ?? false;
  };
}
