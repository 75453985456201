import { APIRequest } from "common/helpers";
import { I_INS_TYPES_ACTIVITY } from "./definitions";

export const getUserActivities = (timeRangeFilter?: string): APIRequest<I_INS_TYPES_ACTIVITY> => {
  return (fetch) =>
    fetch(
      "user/activities/",
      {
        time_range: timeRangeFilter,
      },
      {
        method: "GET",
      },
    );
};
