import { INSTINGO_INS_CONFIG } from "instingo.constants";
import dayjs from "dayjs";
import { C_INS_TYPES_JWT } from "types";
import { jwtDecode } from "./CommonJWTHelpers";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const CommonHelpersGetCookie = (cookieName: string): string | null => {
  return document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)")?.pop() || null;
};

export const CommonHelpersSetCookie = (
  cookieName: string,
  cookieValue: string,
  expires: dayjs.Dayjs = dayjs().add(1, "week"),
  path = "/",
): void => {
  document.cookie = `${cookieName}=${cookieValue};expires=${dayjs
    .utc(expires)
    .toDate()
    .toUTCString()};path=${path};SameSite=Lax;Secure`;
};

export const CommonHelpersDeleteCookie = (cookieName: string): void => {
  document.cookie = `${cookieName}=;expires=${dayjs().subtract(100, "year").utc().toDate().toUTCString()};path=/;`;
};

export const CommonHelpersGetUserCookie = (): string | null => {
  return CommonHelpersGetCookie(INSTINGO_INS_CONFIG.APP.CONFIG.COOKIES.USER_LOGIN_KEY);
};

export const CommonHelpersSetUserCookie = (token: string): void => {
  let jwtToken;

  try {
    jwtToken = new C_INS_TYPES_JWT(jwtDecode(token));
  } finally {
    CommonHelpersSetCookie(INSTINGO_INS_CONFIG.APP.CONFIG.COOKIES.USER_LOGIN_KEY, token, jwtToken?.getExp(), "/");
  }
};

export const CommonHelpersDeleteUserCookie = (): void => {
  CommonHelpersDeleteCookie(INSTINGO_INS_CONFIG.APP.CONFIG.COOKIES.USER_LOGIN_KEY);
};

export const CommonHelpersGetAllCookies = (): Record<string, string | null> | null => {
  const pairs = document.cookie.split(";");
  const cookies: Record<string, any> = {};

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    const key = (pair[0] + "").trim(),
      val = pair.slice(1).join("=");
    if (key || val) {
      cookies[key] = val;
    }
  }

  return Object.keys(cookies).length ? cookies : null;
};
