import { APIRequest } from "common/helpers";
import { I_INS_TYPES_USER } from "./definitions";

interface LoginParams {
  login_id: string;
  password: string;
}

export const login = (params: LoginParams): APIRequest<I_INS_TYPES_USER> => {
  return (fetch) =>
    fetch(
      "user/login/",
      {
        ...params,
      },
      {
        method: "POST",
      },
    );
};
